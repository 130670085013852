import React from 'react';
import mouseIcon from '../images/icons/mouse.png';

export default ({ icon, social, detail, url }) => {
    return (
        <div className='social-container'>
            <div className='social-icon'>
                <img src={icon.default} width={24} height={24} alt={social} />
            </div>
            <div className='social-box'>
                <h1 className='social-title'>{social.toUpperCase()}</h1>
                <p className='social-detail'>{detail}</p>
            </div>
            <div className='social-call'>
                <a href={url}>
                    <img src={mouseIcon} width={24} height={24} alt="Connect" />
                </a>
            </div>
        </div>
    );
}