import React from 'react';

export default ({
    facebookLink,
    facebookIcon,
    linkedInLink,
    linkedInIcon,
    emailLink,
    emailIcon
}) => {
    return (
        <div className="social-box-container">
            <div className='social-icon' style={{ marginLeft: 5, marginRight: 5 }}>
                <a href={facebookLink}>
                    <img src={facebookIcon} width={24} height={24} alt="Facebook" />
                </a>
            </div>
            <div className='social-icon' style={{ marginLeft: 5, marginRight: 5 }}>
                <a href={linkedInLink}>
                    <img src={linkedInIcon} width={24} height={24} alt="LinkedIn" />
                </a>
            </div>
            <div className='social-icon' style={{ marginLeft: 5, marginRight: 5 }}>
                <a href={emailLink}>
                    <img src={emailIcon} width={24} height={24} alt="Email" />
                </a>
            </div>
        </div>
    );
}