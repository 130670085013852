import React from 'react';
import TeamMemberCard from './TeamMemberCard';
import adriaanIcon from '../images/adriaan.JPG';
import wesleyIcon from '../images/wesley.jpg';
import timIcon from '../images/tim.JPG';
import facebookIcon from '../images/icons/facebook.png';
import linkedInIcon from '../images/icons/linkedin.png';
import emailIcon from '../images/icons/email.png';
import backgroundVideo from '../videos/mercurymotion.mp4';

export default () => {
    return (
        <section className="parallax-section" style={{ position: "relative", height: "auto" }}>
            <section className='parallax-section-inner'>
                <video className="parallax-video" autoPlay loop muted>
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                <div 
                    className="mercury-container mercury-container-bottom-margin mercury-container-overlay"
                >
                    <h1 className='service-title'>founding members</h1>
                    <div className="mercury-container-row">
                        <div className="mercury-col-30perc">
                            <TeamMemberCard
                                fullName="Adriaan Wiese"
                                avatarUrl={adriaanIcon}
                                position="Founder | MD"
                                shortSummary="Adriaan Wiese is a Compliance Specialist with a legal background and an interest in law and technology. He also has experience in market research and business development at local and international levels. He has worked as an intermediary in developing business between the Netherlands and South(ern) Africa, including airport development, and completed research for major companies and bodies. He holds the position of Managing Director of Mercury Systems B.V., which provides digital and compliance solutions to the unmanned aviation industry."
                                facebookIcon={facebookIcon}
                                facebookLink=""
                                linkedInIcon={linkedInIcon}
                                linkedInLink=""
                                emailIcon={emailIcon}
                                emailLink="mailto:adriaan@mercurysystems.info"
                            />
                        </div>
                        <div className="mercury-col-30perc">
                            <TeamMemberCard
                                fullName="Timothy van Langeveld"
                                avatarUrl={timIcon}
                                position="CCO"
                                shortSummary="Timothy has vast experience and expertise in aviation regulation, policy, and compliance. After finishing his Master of Laws (LL.M) at the University of Utrecht and his Advanced Master Degree in Air and Space Law (LL.M.) at the prestigious Faculty of Law of the University of Leiden, the Netherlands, he has worked for more than a decade in the industry of unmanned aviation, both in the private sector as well as the Dutch Military and Government. He joined the Civil Aviation Authority of the Netherlands as Senior Inspector and later the Legal Department of the Dutch Ministry of Infrastructure as a Senior Regulation Lawyer and Legal Advisor on Aerospace and Aviation in close cooperation with policymakers at national and EU level."
                                facebookIcon={facebookIcon}
                                facebookLink=""
                                linkedInIcon={linkedInIcon}
                                linkedInLink=""
                                emailIcon={emailIcon}
                                emailLink=""
                            />
                        </div>
                        <div className="mercury-col-30perc">
                            <TeamMemberCard
                                fullName="Wesley Basson"
                                avatarUrl={wesleyIcon}
                                position="Founder"
                                shortSummary="Wesley Basson's background in mechanical engineering has given him a solid foundation in problem-solving and critical thinking, which he has applied to his work in the software engineering field. He has worked in a variety of industries, each of which has presented unique challenges and opportunities for him to learn and grow as an engineer. His experience in the aerospace, telecommunications and insurance industries have given him a unique perspective on the intersection of technology and finance, which has been invaluable in his work in the blockchain and crypto industries."
                                facebookIcon={facebookIcon}
                                facebookLink="https://www.facebook.com/profile.php?id=100009869795295&mibextid=LQQJ4d"
                                linkedInIcon={linkedInIcon}
                                linkedInLink="https://www.linkedin.com/in/wesley-basson/"
                                emailIcon={emailIcon}
                                emailLink="mailto:wesley@litheon.co.za"
                            />
                        </div>
                    </div>
                </div>
                <section className="layer6" />
            </section>
            <section className='layer4' style={{ position: "absolute", top: 0, left: 0, right: 0, height: 200 }} />
        </section>
    );
}