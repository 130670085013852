import React from 'react';

export default ({ partnerLogo, partnerName, partnerText, partnerLink }) => {
    return (
        <div className="partner-card">
            <img className="partner-logo" src={partnerLogo} alt={partnerName} width={128} height={128} />
            <div className="partner-text-container">
                <p className="partner-text">{partnerText}</p>
                <a className="partner-link" style={{ marginTop: 10 }} href={partnerLink}>{partnerLink}</a>
            </div>
        </div>
    );
}