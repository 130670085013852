import React from "react";
import HeaderScroll from "./HeaderScroll";
import NavBar from "./NavBar";
import WelcomeMessage from "./WelcomeMessage";

export default ({ logo, scrollimg }) => {
    return (
        <header className="App-header">
            <NavBar logo={logo} />
            <WelcomeMessage logo={logo} />
            <HeaderScroll scrollimg={scrollimg} />
            <div className="header-bottom-bar" />
        </header>
    );
}