import React from "react";

export default () => {
    return (
        <>
            <section className="mission-section">
                <div style={{ position: "absolute", top: "-4px", left: 0, width: "100%", height: "6px", zIndex: 2, backgroundColor: "#337799" }} />
                <h3 className="mission-title">our mission</h3>
                <p className="mission-text">
                    Our mission is to empower the unmanned revolution by providing compliance, connectivity, and digital solutions.
                    We are committed to excellence and quality in all that we do,
                    and believe that our work can contribute to a better world for everyone.
                    By driving the responsible advancement of the unmanned aviation sector,
                    we are dedicated to creating a brighter future for all people and communities.
                </p>
                <div style={{ position: "absolute", bottom: "-4px", left: 0, width: "100%", height: "6px", zIndex: 2, backgroundColor: "#337799" }} />
            </section>
            <div className="section-divider layer2"></div>
        </>
    );
}