import React from 'react';
import PartnerCard from './PartnerCard';
import partner1Logo from '../images/partners/762.png';
import partner2Logo from '../images/partners/metip.png';

export default () => {
    return (
        <section style={{ paddingTop: 0, paddingBottom: 0 }}>
            <h1 className="service-title" style={{ marginTop: 0 }}>partners</h1>
            <PartnerCard
                partnerLogo={partner1Logo}
                partnerName="762"
                partnerText="Founded in 2018, with more than 10 years of experience in the finance and air-travel sectors. 762 had major contributions to global Self-Service Machine fleet management and software distribution solutions currently deployed on more than 150k ATMs and 200 banks worldwide. In addition to the implementation of the mutually authenticated encrypted communication (SOAP over mTLS), 762 was responsible for security hardening and fulfilment of the strict penetration testing requirements (SQL injection protection, code obfuscation, DoS protection), characteristic of the finance sector. 762, has also provided software development and consulting services for banking, insurance and IoT products. Mercury Systems B.V. is now collaborating with 762 to provide the next-gen GCS-Swarm communication solutions in compliance with upcoming EU standards."
                partnerLink="https://762consulting.com"
            />
            <PartnerCard
                partnerLogo={partner2Logo}
                partnerName="METIP"
                partnerText="Mercury Unmanned Systems is a partner of the Emerging and Innovative Maritime Technologies Platform (METIP) located in Den Helder, The Netherlands. METIP is a collaborative platform that brings together various organizations and companies working on innovative maritime technologies. By partnering with METIP, Mercury Unmanned Systems can work closely with other companies and individuals in the industry, share knowledge and resources, and collaborate on new projects or initiatives. Overall, this partnership allows Mercury Unmanned Systems to stay at the forefront of emerging technologies and trends in the maritime industry, while also potentially expanding reach and capabilities through collaborations with other members of the METIP platform."
                partnerLink="https://metip.nl/over-ons/"
            />
        </section>
    );
}