import React from "react";
import ServiceBox from "./ServiceBox";

export default ({ policyimg, techimg }) => {
    return (
        <>
            <section className="service-section">
                <div className="service-heading">
                    <h1 className="service-title">services</h1>
                    <p className="service-text service-text-bottom">
                        At Mercury Unmanned Systems, we take a holistic approach to ensure that our clients have access
                        to the best unmanned systems, comply with all regulations and standards, and have access to ongoing
                        support and maintenance services. Our team of engineers, policy and compliance professionals work
                        together to provide a wide range of services, including system engineering and development,
                        research and innovation, and 24/7 support. This approach enables our clients to conduct their
                        operations with confidence and peace of mind.
                    </p>
                    {/* <p className="service-text">
                        The modern unmanned aviation ecosystem is a highly complex and multi-disciplinary field
                        that requires a holistic approach to ensure safe and efficient operations.
                        At Mercury Unmanned Systems, we understand the importance of this approach and have built
                        a team of experts who work together to provide our clients with the support they need
                        to conduct their operations with confidence.
                    </p>
                    <p className="service-text">
                        Our team of engineers work closely with our policy and compliance professionals to ensure that
                        all of our systems and procedures adhere to the latest regulations and standards set by national
                        and international aviation authorities. This includes compliance with air traffic control regulations,
                        safety standards, and laws related to unmanned aircraft systems.
                    </p>
                    <p className="service-text">
                        In addition to our focus on compliance, we also place a strong emphasis on innovation and research.
                        Our engineers and researchers are constantly exploring new technologies and developing new methods to
                        improve the performance and capabilities of our systems. This enables us to offer our clients the latest
                        and most advanced unmanned systems available on the market.
                    </p>
                    <p className="service-text">
                        Furthermore, we provide ongoing support and maintenance services to ensure that our clients' systems
                        are always in top working condition. Our team is available 24/7 to provide assistance with any issues
                        or concerns that may arise.
                    </p>
                    <p className="service-text">
                        In summary, at Mercury Unmanned Systems, we take a holistic approach to ensure that our clients have
                        access to the best unmanned systems, comply with all regulations and standards, and have access to
                        ongoing support and maintenance services. This approach enables our clients to conduct their operations
                        with confidence and peace of mind.
                    </p> */}
                </div>
                <div className="service-box">
                    <ServiceBox 
                        title="Urban Air Mobility &amp; Medical Drone Delivery"
                        tag="policy | compliance | regulatory"
                        description="Urban Air Mobility involves using drones to transport goods or people above or between cities, reducing traffic, pollution, and costs. Our extensive European network of stakeholders and participation in European Medical Drone Delivery projects allow us to assist institutions and healthcare providers in establishing safe and efficient medical drone services."
                        img={policyimg}
                        imgAlt="Policy Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                    <ServiceBox
                        title="U-Space"
                        tag="policy | compliance | regulatory"
                        description="U-space, also known as Unmanned Traffic Management (UTM), is a system of services, technologies and agreements that manages air traffic control for drones. We provide both technical and legal support to national and local authorities, U-space service providers, and air navigation service providers in implementing and developing U-space airspace, as part of an ecosystem of stakeholders."
                        img={policyimg}
                        imgAlt="Policy Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                    <ServiceBox
                        title="SORA"
                        tag="policy | compliance | regulatory"
                        description="The SORA methodology, developed by JARUS and incorporated into EASA legislation, is a risk and performance-based approach for the management of unmanned aircraft systems operations. We assist operators in acquiring their operational authorisation by providing guidance and support throughout the process ensuring compliance with regulatory requirements and promoting safe and efficient operations."
                        img={policyimg}
                        imgAlt="Policy Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                </div>
                <div className="service-box">
                    <ServiceBox 
                        title="Software Engineering &amp; Integration"
                        tag="software | technical"
                        description="We develop user-friendly interfaces for pilots and air traffic controllers using languages like HTML, CSS, JavaScript, Python, Java, and Node.js, as well as frameworks like React and Angular. This allows for easy navigation and control, ensuring a smooth and efficient experience for users. We also have expertise in developing backend systems, including web APIs and mediation software using the .NET framework and C# language. This ensures the seamless exchange of data and communication between UAVs and ground-based systems, and enables efficient decision-making and real-time data collection and analysis."
                        img={techimg}
                        imgAlt="Tech Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                    <ServiceBox
                        title="Automation &amp; Digitalisation"
                        tag="software | technical"
                        description="We incorporate AI and IoT capabilities into our software solutions for intelligent decision-making and real-time data collection and analysis. IoT technology enables the unmanned aviation industry to gather vast amounts of data from multiple sources, such as UAVs, weather stations, and air traffic control systems, and then use AI algorithms to analyse and interpret this data in real-time. This allows for improved situation awareness, decision-making and collaboration. By using IoT in conjunction with the SWIM (System Wide Information Management) concept, we enable a seamless flow of information between all stakeholders in the unmanned aviation ecosystem, providing a common operational picture and enabling safer and more efficient operations."
                        img={techimg}
                        imgAlt="Tech Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                    <ServiceBox
                        title="Cyber Security in Aviation"
                        tag="software | technical"
                        description="As manned aircraft increasingly rely on digital and connectivity solutions to operate more safely and efficiently, unmanned aircraft are entirely dependent on these technologies. This makes cyber security a critical concern for the unmanned aviation industry. We offer a range of state-of-the-art encryption methodologies that adhere to international standards and regulations, such as those set by ICAO (International Civil Aviation Organization), EASA (European Union Aviation Safety Agency) and NATO (North Atlantic Treaty Organization). These methodologies help protect against cyber threats, ensuring the integrity and confidentiality of communications and data exchanged between unmanned aircraft and ground-based systems. Our solutions are designed to detect, prevent and respond to cyber-attacks, and we continuously monitor and update our systems to stay ahead of evolving cyber threats."
                        img={techimg}
                        imgAlt="Tech Icon"
                        imgHeight={64}
                        imgWidth={64}
                    />
                </div>
            </section>
        </>
    );
}