import React, { useState } from 'react';
import ContactSocialBox from './ContactSocialBox';
const linkedinIcon = require('../images/icons/linkedin.png');
const emailIcon = require('../images/icons/email.png');
const whatsappIcon = require('../images/icons/whatsapp.png');

export default () => {
  const [alias, setAlias] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [buttondisabled, setButtondisabled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authbody = {
      service: process.env.SERVICENAME,
      passphrase: process.env.MERCURY_PASSPHRASE
    }

    const msg = {
      message: {
        to: [process.env.RIAANMAIL, process.env.WESLEYMAIL],
        from: "noreply@mercurysystems.info",
        subject: "Website enquiry",
        text: `${alias} from ${company} has sent you an enquiry.\n\nTheir message:\n\n${message}\n\nYou can reply to them at ${email} or phone them at: ${phone}.\n\nBest Regards,\nMercury Systems Site`
      }
    };

    try {
      const tokenResponse = await fetch(process.env.AUTHURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(authbody)
      });
      const tokenData = await tokenResponse.json();
      console.log('tokenData: ', tokenData);
      const token = tokenData.token;

      const response = await fetch(process.env.SENDURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(msg),
      });
      const data = await response.json();

      if (data.message === "Email sent") {
        setAlias('');
        setCompany('');
        setEmail('');
        setPhone('');
        setMessage('');
        setButtondisabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section className='contact-section gradient1'>
      <section className='layer4' />
      <section>
        {/* <section className='layer5' /> */}
        <div className="mercury-container mercury-container-bottom-margin">
          <div className="mercury-container-row">
            <div className="mercury-col-40perc mercury-col-border-right">
              <h1 className='contact-title'>MERCURY SYSTEMS</h1>
              <h1 className='contact-title'>CONTACT US</h1>
              <p className='contact-text'>Ready to take your UAS and U-Space operations to the next level? Contact us now to discuss how our solutions can help streamline your business and improve safety and efficiency.</p>
              <ContactSocialBox icon={linkedinIcon} social="LinkedIn" detail="MERCURY UNMANNED SYSTEMS B.V." url="https://www.linkedin.com/company/mercury-unmanned-aerial-solutions" />
              <ContactSocialBox icon={emailIcon} social="Email" detail="Adriaan Wiese: adriaan@mercurysystems.info" url="mailto:adriaan@mercurysystems.info" />
              <ContactSocialBox icon={whatsappIcon} social="WhatsApp" detail="Adriaan Wiese: +31 6 42065323" url="https://wa.me/31642065323" />
            </div>
            <div className="mercury-col-60perc">
              <h1 className="contact-form-title">GET IN TOUCH</h1>
              <form onSubmit={handleSubmit}>
                <div className="contact-form-input-group">
                  <input className="contact-form-input" type="text" placeholder="Alias" value={alias} onChange={(e) => setAlias(e.target.value)} />
                  <input className="contact-form-input" type="text" placeholder="Company" value={company} onChange={(e) => setCompany(e.target.value)} />
                  <input className="contact-form-input" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <input className="contact-form-input" type="tel" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  <input className="contact-form-input" type="text" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                  <button className="contact-form-btn-submit" type="submit" disabled={buttondisabled} style={{ backgroundColor: buttondisabled ? '#444' : '#337799' }}>{buttondisabled ? 'Message Sent!' : 'Send Message'}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}