import React from "react";

export default ({ title, tag, description, img, imgAlt, imgHeight = 64, imgWidth = 64 }) => {
    return (
        <div className="service-block">
            <div className="service-block-header">
                <p className="service-block-header-text">
                    {title}
                </p>
                <img src={img} className="service-block-header-image" height={imgHeight} width={imgWidth} alt={imgAlt} />
                <div className="service-block-header-tag">
                    <p>{tag}</p>
                </div>
            </div>
            <div className="service-block-detail">
                <p>{description}</p>
            </div>
        </div>
    );
}