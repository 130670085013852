import React from "react";

export default () => {
    return (
        <>
            <section>
                {/* <h3 className="intro-title">mercury</h3> */}
                <p className="intro-text">
                    At Mercury Unmanned Systems, we specialise in developing solutions for the modern,
                    multi-disciplinary unmanned aviation ecosystem, with a particular focus on U-Space integration.
                    Our team of engineers work alongside compliance professionals,
                    going above and beyond to enable our clients to conduct operations with confidence in the Digital European Sky.
                </p>
                <div className="intro-image" />
            </section>
            <div className="section-divider layer1"></div>
        </>
    );
}