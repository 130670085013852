import React from 'react';
import SocialBox from './SocialBox';

export default ({ 
    fullName,
    avatarUrl,
    position,
    shortSummary,
    facebookIcon,
    facebookLink,
    linkedInIcon,
    linkedInLink,
    emailIcon,
    emailLink
}) => {
    return (
        <div className="team-member-card">
            <img className="avatar" src={avatarUrl} alt={fullName} width={128} height={128} />
            <h1 className="team-member-name-text">{fullName}</h1>
            <p className="team-member-text">{position}</p>
            <p className="team-member-text" style={{ marginTop: 10 }}>{shortSummary}</p>
            <SocialBox
                facebookIcon={facebookIcon}
                facebookLink={facebookLink}
                linkedInIcon={linkedInIcon}
                linkedInLink={linkedInLink}
                emailIcon={emailIcon}
                emailLink={emailLink}
            />
        </div>
    );
}