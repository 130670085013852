import React from "react";
import WelcomeBoxes from "./WelcomeBoxes";

export default ({ logo }) => {
    return (
        <>
            <img src={logo} className="header-welcome-image" alt="Welcome Image" />
            <svg height="100" width="420" stroke="#337799" strokeWidth="2" className="header-welcome-text">
                <text x="45" y="90" fill="none" fontSize="100">Welcome</text>
            </svg>
            <p className="header-welcome-message">to the home of mercury unmanned systems</p>
            {/* <WelcomeBoxes /> */}
        </>
    );
}