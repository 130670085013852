import React from "react";

export default ({ logo }) => {
    return (
        <div className="app-nav">
            <div className="app-nav-logo-box">
                <img src={logo} className="app-nav-logo" alt="Mercury Logo" />
            </div>
            <div className="app-nav-title-box">
                <p className="app-nav-title">Mercury Unmanned Systems</p>
            </div>
        </div>
    );
}