import logo from './images/logo.png';
import scrollimg from './images/scroll.png';
import policyimg from './images/policy.png';
import techimg from './images/tech.png';
import './css/App.css';
import './scss/styles.scss';
import './scss/styles-mercury.scss';
import MainHeader from './components/MainHeader';
import SectionIntro from './components/SectionIntro';
import SectionMission from './components/SectionMission';
import SectionServices from './components/SectionServices';
import SectionContact from './components/SectionContact';
import Divider from './components/Divider';
import Footer from './components/Footer';
import SectionTeam from './components/SectionTeam';
import SectionPartners from './components/SectionPartners';

function App() {
  return (
    <div className="App">
      <MainHeader logo={logo} scrollimg={scrollimg} />
      <SectionIntro />
      <SectionMission />
      <SectionServices policyimg={policyimg} techimg={techimg} />
      <SectionTeam />
      <SectionPartners />
      <SectionContact />
      {/* <Divider />
      <Footer /> */}
    </div>
  );
}

export default App;
